import React from "react"
import Anchor from "../../core/components/anchor"
import Button from "../../client/compositions/button"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Row from "../../core/components/row"
import Tagline from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

import products from "../../client/data/products.json"

/**
 * Products Page
 * @see https://zpl.io/a3nzPq8
 */
function ProductsPage() {
  return (
    <article id="page-products" className="page-content">
      {quickMenu}

      <Banner title="Products" showScroll={true} className="title-only">
        <DecorationFluid image="products/banner.jpg" />
      </Banner>

      {products.map(({ slug, name, url, productThumbnail }, i) => (
        <Row
          key={slug}
          id={slug}
          className={`collection ${i % 2 ? "flipped" : ""}`}
        >
          <Block className="block-content">
            <div className="message">
              <Anchor to={url}>
                <h4 className="suptitle">Collection</h4>
                <h3 className="title">{name}</h3>
              </Anchor>
              <div className="counter">
                <em>{i + 1}</em> <span>/ {products.length}</span>
              </div>
            </div>
            <Button to={url} image="icons/arrow-right.svg">
              Discover
            </Button>
          </Block>
          <Block className="block-media">
            <Decoration image={productThumbnail} disableLazyLoad={true} />
          </Block>
        </Row>
      ))}
      <Tagline />
    </article>
  )
}

export default ProductsPage
